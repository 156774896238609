<template>
  <NetworkOffline v-if="!onLine" />
  <NetworkReconnecting v-else-if="showBackOnline" />
  <div class="app-wrapper h-full dark:bg-black overflow-hidden" dir="ltr">
    <!-- .header start -->
    <Header />
    <!-- .header finish -->
    <!-- .popup-manager start -->
    <teleport to="#popup-wrapper"><PopupManager /></teleport>
    <!-- .popup-manager finish -->
    <Index />
  </div>
</template>

<script setup>
  import {
    onBeforeMount,
    onMounted,
    onUnmounted,
    reactive,
    ref,
    watch,
  } from "vue"
  import Header from "@/components/global/header/Header.vue"
  import PopupManager from "@/components/global/PopupManager.vue"
  import NetworkOffline from "@/templates/NetworkOffline.vue"
  import NetworkReconnecting from "@/templates/NetworkReconnecting.vue"
  import Index from "@/pages/index.vue"
  import { deferredPrompt } from "@/store/pwa"
  import { useRoute, useRouter } from "vue-router"
  import { caughtBrowser } from "@/utils/settings"
  import { getRouteQueryParams, getDate } from "@/utils/settings"
  import { box } from "@/utils/box"

  const route = useRoute()
  const router = useRouter()

  const onLine = ref(navigator.onLine)
  const showBackOnline = ref(false)

  const showUpdateNotification = () => {
    if (Notification?.permission === "granted") {
      new Notification("Yeni bir güncelleme mevcut.", {
        body: "Lütfen sayfayı yeniden yükleyin.",
      })
    }
  }

  const updateOnlineStatus = (e) => {
    const { type } = e
    onLine.value = type === "online"
  }

  watch(
    onLine,
    (v) => {
      if (v) {
        showBackOnline.value = true
        setTimeout(() => {
          showBackOnline.value = false
        }, 1000)
      }
    },
    { deep: true }
  )

  onMounted(() => {
    box.browser = caughtBrowser()
    window.addEventListener("online", updateOnlineStatus)
    window.addEventListener("offline", updateOnlineStatus)
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault()
      deferredPrompt.value = e
    })

    // if (Notification.permission !== "granted") {
    //   Notification.requestPermission().then((permission) => {
    //     if (permission === "granted") {
    //       showUpdateNotification();
    //     }
    //   });
    // } else {
    //   showUpdateNotification();
    // }
  })

  onBeforeMount(() => {
    window.removeEventListener("online", updateOnlineStatus)
    window.removeEventListener("offline", updateOnlineStatus)
  })
</script>
