const { format } = require("date-fns");
import { userSettings } from "@/store/userSettings";
import trLocale from "date-fns/locale/tr";
import { browsers } from "@/utils/enums";

export const getDate = (date) => {
  let newDate = date;
  // Eğer backendden tarih dönmüyorsa otomatik bu günün tarihini alıyorum
  if (!newDate) newDate = new Date();
  const originalDate = new Date(newDate);
  let formattedDate = "";
  if (userSettings.language === "tr" || userSettings.language === "tr-TR")
    formattedDate = format(originalDate, "dd MMMM yyyy EEEE HH:mm", {
      locale: trLocale,
    });
  else formattedDate = format(originalDate, "MMMM d yyyy, HH:mm");

  return formattedDate;
};

export const getRouteQueryParams = (path) => {
  if (!path) return null;
  return path.substring(1);
};

export const changeFormBlobToFormData = (data) => {
  var byteCharacters = atob(data.split(",")[1]);
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var blob = new Blob([byteArray], { type: "image/jpeg" });

  return blob;
};

export const getUserLanguage = (language) => {
  if (language == "tr") return "tr";
  if (language == "nl") return "nl";
  else return "en";
};

export const caughtBrowser = () => {
  const userAgent = navigator.userAgent;
  let browserName = "Unknown";

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = browsers[1];
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = browsers[2];
  } else if (userAgent.match(/safari/i)) {
    browserName = browsers[3];
  } else if (userAgent.match(/opr\//i)) {
    browserName = browsers[4];
  } else if (userAgent.match(/edg/i)) {
    browserName = browsers[5];
  } else if (userAgent.match(/msie|trident/i)) {
    browserName = browsers[6];
  }

  return browserName;
};
