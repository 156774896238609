<template>
  <div
    @click.stop="openNavigationShare"
    class="w-[30px] h-[30px] bg-white dark:bg-dark-soft shadow-md drop-shadow-lg rounded-full flex items-center justify-center dark:text-text-dark-2 text-black absolute right-[10px] bottom-[10px] z-[1]"
  >
    <fai class="text-xs" icon="share-from-square" />
  </div>
</template>

<script setup>
  import { reactive, ref, computed } from "vue"
  import { useI18n } from "vue-i18n"

  const { t } = useI18n()

  const props = defineProps({
    sharing: {
      type: Object,
      required: false,
    },
    message: {
      type: Object,
      default: {},
    },
    withOriginalMessage: {
      type: Boolean,
      default: false,
    },
  })

  const shareTextWithOriginalMessage = computed(() => {
    return (
      props.sharing.title +
      "\n \n" +
      "- - -" +
      " \n \n" +
      props.message.originalMessage +
      " \n \n"
    )
  })

  const shareText = computed(() => {
    return props.sharing.title + "\n \n" + " \n \n"
  })

  const openNavigationShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: props.sharing.title,
          text: props.withOriginalMessage
            ? shareTextWithOriginalMessage.value
            : shareText.value,
          url: props.sharing.link,
        })
        .then(() => console.log(""))
        .catch((err) => console.log("err", err))
    } else {
      console.log("navigator share not available")
    }
  }
</script>
