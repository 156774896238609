<template>
  <div class="!mt-[6px]">
    <div class="audio-player relative">
      <div
        class="bg-blue-500 text-white w-10 h-10 rounded-full flex items-center justify-center absolute left-0 top-2"
        @click="toggleMute"
      >
        <fai :icon="isMuted ? 'volume-mute' : 'volume-high'" />
      </div>
      <audio
        ref="audio"
        :src="props.link"
        muted
        @ended="handleAudioEnded"
        @loadedmetadata="checkForAudio"
        @error="handleError"
      ></audio>
      <Vue3Lottie
        v-if="!audioEnded"
        class="!w-[260px] !ml-12"
        :loop="true"
        :animationData="VoiceLottie"
      />
      <div v-else class="h-[55.3px]"></div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, inject } from "vue"
  import VoiceLottie from "/public/assets/lotties/voice-lottie.json"

  const props = defineProps({
    link: {
      type: String,
      default: "",
    },
    delay: {
      type: Number,
    },
    message: {
      type: Object,
    },
  })

  const isMuted = ref(true)
  const audio = ref(null)
  const audioEnded = ref(false)

  const hasAudio = inject("hasAudio")
  const streamCounter = inject("streamCounter")

  onMounted(() => {
    audioPlayerDelay()
  })

  const checkForAudio = () => {
    const video = audio.value

    if (video.duration > 0) {
      hasAudio.value = true
    } else {
      hasAudio.value = false
    }
  }

  const toggleMute = () => {
    const audioElement = audio.value
    isMuted.value = !isMuted.value
    audioElement.muted = isMuted.value
  }

  const handleAudioEnded = () => {
    audioEnded.value = true
    streamCounter.value++
  }

  const firstStart = () => {
    if (!hasAudio.value) return
    const audioElement = audio.value
    audioElement.play()
  }

  const audioPlayerDelay = () => {
    const delay = setInterval(() => {
      firstStart()
      clearInterval(delay)
    }, props.delay)
  }
</script>

<style scoped>
  .audio-player {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
</style>
