<template>
  <div class="message-box relative chatbot !mt-[6px]">
    <span class="block mb-0.5">{{ attachment.text }}</span>
    <Vue3Lottie
      v-if="attachment.link"
      class="!w-[150px] !mt-3.5 !mb-2"
      :class="getLottieClass(attachment.link)"
      :animationData="getLottiePath(attachment.link)"
    />
  </div>
</template>

<script setup>
  import CameraPermission from "/public/assets/lotties/camera_permission.json"

  const props = defineProps({
    attachment: {
      type: Object,
      default: {},
    },
  })

  const getLottiePath = (data) => {
    if (!data) return
    const caughtLottie = data.split("/").pop()
    if (caughtLottie === "camera_permission.json") return CameraPermission
  }

  const getLottieClass = (data) => {
    if (!data) return
    const caughtLottie = data.split("/").pop()
    if (caughtLottie === "camera_permission.json")
      return "camera-permission-lottie"
  }
</script>
