export const messageTypes = {
  USER: 1,
  CHATBOT: 2,
}

export const sendMessageTypes = {
  readyAnswers: 1,
  keyboard: 2,
  firstMessage: 3,
}

export const originalMessageTypes = {
  VIDEO: 1,
  POLICY: 2,
}

// the type of NEXT user message
export const userMessageTypes = {
  BUTTON: 1, // when user needs to press a button
  TEXT: 2, // when user needs to type something
  CAMERA_REAR: 3, // when user needs to send image via rear camera
  CAMERA_FRONT: 4, // when user needs to send image via front camera
  PASSWORD: 5, // when user needs to type something in password mode
  IMAGE: 6,
}

// the type of CURRENT laila message
export const lailaMessageTypes = {
  DEFAULT: 1, // default message type
  READING: 2, // when laila sends a reading
}

// the type of the attachment with "link" and "text" fields
export const attachmentTypes = {
  LINK: 1, // to embed a link as button-ish style (eg. terms & conditions)
  LOTTIE: 2, // to embed a lottie (eg. cup, reading)
  IMAGE: 3, // to embed link inside an img tag (eg. art, qr)
  IFRAME: 4, // to embed link inside an iframe tag (eg. video, mesh)
  TEXT: 5, // to embed text over other attachments (eg. lottie)
  VOICE: 6,
  ANIMATION: 7,
}

export const cameraModalTypes = {
  NAVIGATE: 1,
  NATIVE: 2,
}

export const browsers = {
  1: "Chrome",
  2: "Firefox",
  3: "Safari",
  4: "Opera",
  5: "Edge",
  6: "Internet Explorer",
}
