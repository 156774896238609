<template>
  <img
    @click="
      showImageForPopup(
        compData.message ? compData.message : compData.localImage
      )
    "
    :src="compData.message ? compData.message : compData.localImage"
  />
</template>

<script setup>
import { box } from "@/utils/box";
import ImageModal from "@/components/composables/modal/ImageModal.vue";
const props = defineProps({
  compData: {
    type: Object,
    default: {},
  },
});

const showImageForPopup = (image) => {
  box.openModal(ImageModal, { image }, "w-[92%]");
};
</script>
