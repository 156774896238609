<template>
  <form
    ref="form"
    id="message-form"
    class="bg-white dark:bg-dark-soft w-full h-[42px] flex items-center justify-evenly rounded-[4rem]"
    @submit.prevent="messageActionsEqual ? void 0 : sendMessage()"
  >
    <textarea
      ref="textarea"
      @input="resize"
      @focus="inputFocus"
      @blur="blurFocus"
      @keydown.enter.exact.prevent="
        messageActionsEqual ? void 0 : sendMessage()
      "
      v-model="formData.query"
      :disabled="messageActionsEqual"
      class="w-[85%] bg-transparent outline-none resize-none h-[42px] py-[7px] px-[16px]"
      type="text"
      id="message-input"
    />
    <button
      @click="messageActionsEqual ? void 0 : sendMessage()"
      :disabled="messageActionsEqual"
      type="submit"
      id="message-submit"
      class="w-[37px] h-[37px] rounded-full flex items-center justify-center text-[19px] text-white border-0"
      :class="messageActionsEqual ? 'bg-icon-color' : 'send-message-btn'"
    >
      <fai class="mr-0.5" icon="paper-plane" />
    </button>
  </form>
</template>
<script setup>
import { reactive, ref, computed } from "vue";
import { userMessageTypes } from "@/utils/enums.js";

const props = defineProps({
  allMessages: {
    type: Array,
    default: [],
  },
  loaders: {
    type: Object,
    default: {},
  },
});

const emits = defineEmits(["sendMessage"]);

const textarea = ref(null);
const form = ref(null);

const formData = reactive({
  query: "",
});

// all actions disabled
const messageActionsEqual = computed(() => {
  if (
    props.loaders.sendMessage ||
    props.allMessages[0]?.userMessageType == userMessageTypes.BUTTON ||
    !props.allMessages[0]?.messageWriter ||
    props.allMessages[0]?.userMessageType == userMessageTypes.CAMERA_REAR ||
    props.allMessages[0]?.userMessageType == userMessageTypes.CAMERA_FRONT
  ) {
    return true;
  } else {
    return false;
  }
});

// textarea options
const resize = (e) => {};

const inputFocus = () => {
  form.value.style.borderRadius = "0.8rem";
};

const blurFocus = () => {
  form.value.style.borderRadius = "4rem";
};

const sendMessage = () => {
  if (formData.query.length === 0) return;
  emits("sendMessage", { query: formData.query });
  formData.query = "";
};
</script>
