export const localSetItem = (key, value) => {
  if (!value || !key) return;
  localStorage.setItem(key, value);
};

export const localGetItem = (value) => {
  if (!value) return;
  return localStorage.getItem(value);
};

export const localRemoveItem = (value) => {
  if (!value) return;
  return localStorage.removeItem(value);
};
