<template>
  <div
    class="w-[45px] h-[40px] flex items-center relative justify-center bg-white dark:bg-dark-soft rounded-full"
    @click="openCameraPopup()"
  >
    <div class="shine w-full h-full absolute"></div>
    <fai class="text-theme-secondary" icon="camera" />
    <!-- <Vue3Lottie :animationData="CameraLottie" :height="200" :width="200" /> -->
  </div>
</template>

<script setup>
import { box } from "@/utils/box";
import { reactive, ref } from "vue";
import CameraLottie from "/public/assets/lotties/camera_button.json";
import CameraModal from "@/components/composables/modal/cameramodal/CameraModal.vue";
import { userSettings } from "@/store/userSettings";
import { localGetItem, localSetItem } from "@/utils/local";
const props = defineProps({
  sendMessageWithImage: {
    type: Function,
    default: void 0,
  },
  attachment: {
    type: Object,
    // default: {
    //   type: 5,
    //   text: "Tuğberk please make sure\nto fit your cup in the green area.",
    // },
    default: {},
    required: false,
  },
});

// Camera Popup
const openCameraPopup = () => {
  box.openModal(
    CameraModal,
    {
      sendMessage: sendMessage,
      attachment: props.attachment,
    },
    "w-full h-screen"
  );
};

const sendMessage = ({ image, query }) => {
  props.sendMessageWithImage({ image: image, query });
};
</script>
