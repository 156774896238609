<template>
  <div
    class="t-modal fixed inset-0 flex items-center justify-center z-10"
    :class="className"
  >
    <!-- .t-modal start -->
    <div :class="props.popupsData.style" v-if="showPopupContent">
      <!-- .close-modal start -->
      <div
        @click="closeDialog(popupsData.id)"
        class="close-modal w-[25px] h-[25px] bg-white flex items-center justify-center rounded-full absolute left-5 top-3 z-50"
      >
        <fai class="text-theme-primary" icon="xmark" />
      </div>
      <!-- .close-modal finish -->

      <slot name="body"> </slot>
    </div>

    <!-- .t-modal finish -->
  </div>
</template>
<script setup>
import { closePopup, popups } from "@/utils/widget/popup.js";
import { computed } from "vue";

const props = defineProps({
  popupsData: {
    type: Object,
  },
  extraFunction: {
    type: Function,
    default: () => void 0,
  },
  className: {
    type: String,
    default: "bg-black bg-opacity-80",
  },
});

const emits = defineEmits(["popupClosed"]);

const closeDialog = (id) => {
  props.extraFunction();
  closePopup(id);
  emits("popupClosed");
};

const showPopupContent = computed(() =>
  popups.some((p) => p.id == (props.popupsData && props.popupsData.id))
);
</script>
