<template>
  <div
    class="absolute left-[10px] top-[5px] flex gap-3 items-center bg-black px-6 py-5 w-[95%] rounded z-50"
  >
    <div class="text-[#32CD32] text-3xl" id="icon-box">
      <fai icon="wifi" />
    </div>
    <span class="text-white">{{ $t("connecting") }}</span>
  </div>
</template>
<script setup></script>
