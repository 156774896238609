<template>
  <Popup :popupsData="popupsData">
    <template #body>
      <div
        class="flex flex-col items-center justify-center bg-white px-8 py-11 rounded-md gap-[15px]"
      >
        <div
          class="icon-box w-[85px] h-[85px] bg-red-100 text-red-500 flex items-center justify-center rounded"
        >
          <fai class="text-5xl" icon="circle-exclamation" />
        </div>
        <div class="content text-center">
          <h5 class="font-semibold">
            {{ confirmationDialogData.title }}
          </h5>
        </div>
        <div class="footer flex gap-6 mt-4">
          <button
            @click="yesToggle"
            class="px-2.5 py-1.5 w-[80px] bg-green-600 text-white rounded-md text-[18px]"
          >
            {{ $t("yes") }}
          </button>
          <button
            @click="deletePopup"
            class="px-2.5 py-1.5 w-[80px] bg-red-600 text-white rounded-md text-[18px]"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </template>
  </Popup>
</template>
<script setup>
import { version } from "../../../../package.json";
import { ref, onMounted } from "vue";
import { deferredPrompt } from "@/store/pwa";
import { closePopup } from "@/utils/widget/popup";
const props = defineProps({
  popupsData: {},
});

const { confirmationDialogData } = props.popupsData.data;

const yesToggle = () => {
  confirmationDialogData.callback();
  deletePopup();
};

const deletePopup = () => {
  closePopup(props.popupsData.id);
};
</script>
