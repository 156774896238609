<template>
  <Popup>
    <template #body>
      <!--  #user-menu-header start -->
      <div
        id="user-menu-header"
        class="h-[45px] w-full bg-theme-primary fixed top-0"
      ></div>
      <!--  #user-menu-header finish -->
      <!-- #user-menu-content start -->
      <ul class="user-menu-wrapper flex flex-col absolute inset-0 top-[50px]">
        <template v-for="(item, index) in userMenus" :key="index">
          <li
            v-if="item.caseColon"
            @click="() => executeCallback(item)"
            class="py-[15px] px-[55px] flex items-center relative"
            :class="item.class"
          >
            <div
              class="menu-icon w-[30px] h-[30px] flex items-center justify-center absolute left-[15px]"
            >
              <fai
                class="fill-theme-primary text-theme-primary"
                :icon="item.icon"
              />
            </div>
            <div class="menu-content font-medium">
              <h5>
                {{ $t(item.title) }}
              </h5>
              <p class="text-xs mt-0.5" v-if="item.subTitle">
                {{ $t(item.subTitle) }}
              </p>
            </div>
            <div class="detail-icon absolute right-[15px]">
              <fai :icon="item.secondaryIcon" />
            </div>
          </li>
        </template>
        <span
          id="version"
          class="w-full flex items-center justify-center mt-[20px] text-sm font-medium"
        >
          V {{ version }}
        </span>
      </ul>
      <!-- #user-menu-content finish -->
    </template>
  </Popup>
</template>
<script setup>
import { version } from "../../../../package.json";
import { ref, onMounted } from "vue";
import { deferredPrompt } from "@/store/pwa";
import { i18n } from "@/i18n/index";
import ConfirmationPopup from "@/components/composables/modal/ConfirmationPopup.vue";
import { box } from "@/utils/box";
import { useI18n } from "vue-i18n";
import services from "@/services/index";
import { userSettings } from "@/store/userSettings";
import { localRemoveItem, localGetItem } from "@/utils/local";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const { t } = useI18n();

const executeCallback = (item) => {
  if (item && item.callback) {
    item.callback(item);
  }
};

const redirectPage = (item) => {
  if (!item) return;
  window.open(item.url, "_blank");
};

// Delete chat start

const deleteChat = async () => {
  await services
    .deleteChat({ sessionId: userSettings.session_id })
    .then((res) => {
      window.location.reload({ queryString: "delete-my-chat-history" });
    })
    .catch((err) => {
      console.log("err", err);
    });
};

const toggleDeleteChat = () => {
  const confirmationDialogData = {
    title: t("delete_chat_confirmation"),
    callback: deleteChat,
    type: "warning",
  };
  box.openModal(
    ConfirmationPopup,
    { confirmationDialogData: confirmationDialogData },
    "w-[85%] h-screen flex items-center justify-center "
  );
};

// Delete chat finish

// Delete user start

const deleteUser = () => {
  services
    .deleteUser()
    .then((res) => {
      removeLocalStorage({ queryString: "delete-my-account" });
    })
    .catch((err) => {
      console.log("err", err);
    });
};

const toggleDeleteUser = () => {
  const confirmationDialogData = {
    title: t("delete_confirmation"),
    callback: deleteUser,
    type: "warning",
  };
  box.openModal(
    ConfirmationPopup,
    { confirmationDialogData: confirmationDialogData },
    "w-[85%] h-screen flex items-center justify-center "
  );
};

// Delete user finish

const toggleLogout = () => {
  logout();
};

const installPWA = () => {
  if (deferredPrompt.value) {
    deferredPrompt.value.prompt();

    deferredPrompt.value.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("Kullanıcı PWA'yı yüklemeyi kabul etti");
        } else {
          console.log("Kullanıcı PWA yüklemeyi reddetti");
        }
        deferredPrompt.value = null;
      })
      .catch((err) => {
        console.error("PWA yüklerken hata oluştu:", err);
      });
  }
};

const logout = async () => {
  await services
    .logout()
    .then((res) => {
      removeLocalStorage({ queryString: "log-me-out" });
    })
    .catch((err) => {
      console.log("err", err);
    });
};

const removeLocalStorage = ({ queryString }) => {
  localRemoveItem("userSettings");
  const isThereaUser = localGetItem("userSettings");
  if (!isThereaUser) {
    if (!queryString) return window.location.reload();
    var existingQueryString = window.location.search;

    var newQueryString = queryString;

    console.log("newQuery", newQueryString);

    var newUrl =
      window.location.origin +
      existingQueryString +
      (existingQueryString ? "&" : "?dfKey=") +
      newQueryString;

    window.location.href = newUrl;
  }
};

const userMenus = [
  {
    icon: "info",
    secondaryIcon: "chevron-right",
    title: "tab_1",
    url: i18n.t("tab_1_link"),
    callback: redirectPage,
    class: "bg-transparent",
    caseColon: true,
  },
  {
    icon: "mug-hot",
    secondaryIcon: "chevron-right",
    title: "tab_2",
    url: i18n.t("tab_2_link"),
    callback: redirectPage,
    class: "bg-transparent",
    caseColon: true,
  },
  {
    icon: "holly-berry",
    secondaryIcon: "chevron-right",
    title: "tab_3",
    url: i18n.t("tab_3_link"),
    callback: redirectPage,
    class: "bg-transparent",
    caseColon: true,
  },
  {
    icon: "file-signature",
    secondaryIcon: "chevron-right",
    title: "tab_4",
    url: i18n.t("tab_4_link"),
    callback: redirectPage,
    class: "bg-transparent",
    caseColon: true,
  },
  {
    icon: "pen-to-square",
    secondaryIcon: "chevron-right",
    title: "tab_5",
    url: i18n.t("tab_5_link"),
    callback: redirectPage,
    class: "bg-transparent",
    caseColon: true,
  },
  // {
  //   icon: "download",
  //   secondaryIcon: "chevron-right",
  //   title: "tab_6",
  //   url: i18n.t("tab_6_link"),
  //   callback: installPWA,
  //   class: "bg-transparent",
  //   caseColon: true,
  // },
  {
    icon: "comment-slash",
    secondaryIcon: "trash-can",
    title: "tab_delete_chat",
    subTitle: "tab_delete_chat_subtext",
    callback: toggleDeleteChat,
    class: "bg-slate-50",
    caseColon: userSettings.isRegistered,
  },
  {
    icon: "user-slash",
    secondaryIcon: "trash-can",
    title: "tab_delete_user",
    subTitle: "tab_delete_user_subtext",
    callback: toggleDeleteUser,
    class: "bg-slate-50",
    caseColon: userSettings.isRegistered,
  },
  {
    icon: "right-from-bracket",
    title: "logout",
    callback: toggleLogout,
    class: "bg-slate-50",
    caseColon: userSettings.isRegistered,
  },
];
</script>

<!-- eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI1NzQ1OWQ4MS01ZGJjLTQwMzAtOWRmNC03NWExMGYyNTA4N2IifQ.oO20zQ3nWhTzqm0GmVgv5HVzQzmQGhrF47Yvz5XBo8Y -->
