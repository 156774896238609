<template>
  <div class="application-right flex gap-[20px] mr-0.5">
    <!-- #user-volume start -->

    <div
      @click="toggleVolume"
      class="icon-box rounded-full text-white flex items-center justify-center -mr-[2px]"
    >
      <fai v-if="userSettings.messageSound" icon="volume-high" />
      <fai v-else icon="volume-xmark" />
    </div>
    <!-- #user-volume finish -->
    <!-- #user-ui mode start -->
    <div
      @click="toggleUserTheme"
      class="icon-box rounded-full text-white flex items-center justify-center"
    >
      <fai
        v-if="userSettings.userTheme === 'light'"
        icon="circle-half-stroke"
      />
      <fai v-else icon="sun" />
    </div>
    <!-- #user-ui mode finish -->
    <!-- #user-menu start -->
    <div
      @click="toggleUserMenu"
      class="icon-box rounded-full text-lg text-white flex items-center justify-center"
    >
      <fai icon="square-caret-down" />
    </div>
    <!-- #user-menu finish -->
  </div>
</template>
<script setup>
import { localSetItem, localGetItem } from "@/utils/local.js";
import { userSettings } from "@/store/userSettings.js";
import { box } from "@/utils/box";
import UserMenu from "@/components/composables/modal/UserMenu.vue";
const toggleVolume = () => {
  const newObj = JSON.parse(localGetItem("userSettings"));
  if (!newObj) return;
  newObj.messageSound = !newObj.messageSound;
  Object.assign(userSettings, newObj);
  localSetItem("userSettings", JSON.stringify(newObj));
};

const toggleUserTheme = () => {
  const newObj = JSON.parse(localGetItem("userSettings"));
  if (!newObj) return;
  if (newObj.userTheme === "light") {
    newObj.userTheme = "dark";
    Object.assign(userSettings, newObj);
    document.documentElement.classList.add("dark");
    return localSetItem("userSettings", JSON.stringify(newObj));
  } else {
    newObj.userTheme = "light";
    Object.assign(userSettings, newObj);
    document.documentElement.classList.remove("dark");
    return localSetItem("userSettings", JSON.stringify(newObj));
  }
};

const toggleUserMenu = () => {
  box.openModal(UserMenu, {}, "w-full h-screen bg-white dark:bg-dark-soft");
};
</script>
