<template>
  <Popup :popupsData="popupsData">
    <template #body>
      <div class="flex items-center justify-center">
        <iframe
          class="w-full h-[250px]"
          :src="props.popupsData.data.attachment.link"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </template>
  </Popup>
</template>
<script setup>
const props = defineProps({
  popupsData: {},
});
</script>
