<template>
  <div v-if="hasAudio" class="message-box relative chatbot !mt-[6px]">
    <div
      @click="() => (show = !show)"
      id="minimize-chat"
      class="absolute right-1 z-10"
      :class="show ? 'top-1' : 'top-2.5'"
    >
      <div
        class="w-5 h-5 bg-blue-500 text-white rounded-full text-[9px] flex items-center justify-center"
      >
        <fai :icon="show ? 'chevron-up' : 'chevron-down'" />
      </div>
    </div>
    <div :class="!show && 'hidden'" class="audio-player relative">
      <transition name="list">
        <KeepAlive>
          <component
            :link="props.attachment.link"
            :message="message"
            :is="
              streamCounter == 0 ? CommonAudioPlayerStream : CommonAudioPlayer
            "
          />
        </KeepAlive>
      </transition>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, provide } from "vue"
  import CommonAudioPlayerStream from "@/components/composables/audio/CommonAudioPlayerStream.vue"
  import CommonAudioPlayer from "@/components/composables/audio/CommonAudioPlayer.vue"

  const props = defineProps({
    attachment: {
      type: Object,
      default: {},
    },
    delay: {
      default: 1500,
    },
    message: {
      type: Object,
      default: {},
    },
  })

  const streamCounter = ref(0)

  const show = ref(true)
  const hasAudio = ref(true)

  provide("streamCounter", streamCounter)
  provide("hasAudio", hasAudio)
</script>
