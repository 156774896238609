import { box } from "@/utils/box";
import Axios from "axios";
import { localGetItem, localSetItem } from "@/utils/local";

const axios = Axios.create({
  baseURL: box.baseURL,
});

axios.interceptors.request.use(
  (config) => {
    let userSettings = JSON.parse(localGetItem("userSettings"));
    if (userSettings) {
      config.headers["authorization"] = `${userSettings.token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
