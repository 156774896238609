import { markRaw, reactive, ref } from "vue";
import { v4 as uuidv4 } from "uuid";

export const popups = reactive([]);

export const createModal = (component, data, style) => {
  const item = {
    component: markRaw(component),
    data,
    style,
    id: uuidv4(),
  };
  //
  popups.push(item);
};

export const closePopup = (id) => {
  if (!id) return;
  const index = popups.findIndex((item) => item.id == id);
  if (index >= 0) {
    popups.splice(index, 1);
  }
};

export const deletePopup = (data) => {
  let index = popups.findIndex((xx) => xx.uniqId == data.id);
  if (index !== -1) {
    if (typeof popups[index].callback === "function") {
      let fn = popups[index];
      popups.splice(index, 1);
      fn.callback(fn.data);
    } else {
      console.log("popups", popups);
      popups.splice(index, 1);
    }
  }
};
