import { createI18n } from "vue-i18n";
import { getLocaleMessages } from "./utils";
import { userSettings } from "@/store/userSettings";
import { getUserLanguage } from "@/utils/settings";

const instance = createI18n({
  legacy: false,
  locale: getUserLanguage(userSettings.language),
  fallbackLocale: getUserLanguage(userSettings.language),
  messages: getLocaleMessages(),
});

export default instance;

export const i18n = instance.global;
