<template>
  <Popup :popupsData="popupsData">
    <template #body>
      <img class="object-contain" :src="props.popupsData.data.image" />
    </template>
  </Popup>
</template>
<script setup>
const props = defineProps({
  popupsData: {},
});
</script>
