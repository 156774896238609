function importAll(r) {
  const messages = {};
  r.keys().forEach((key) => {
    const locale = key.match(/([a-z0-9_]+)\./i)[1];
    messages[locale] = r(key).default;
  });
  return messages;
}

export function getLocaleMessages() {
  return importAll(require.context("./locales", true, /\.yaml$/));
}
