import { library } from "@fortawesome/fontawesome-svg-core"

import {
  faMoon,
  faSun,
  faGear,
  faGears,
  faCamera,
  faPaperPlane,
  faCheckDouble,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faExclamation,
  faCircleXmark,
  faComments,
  faUser,
  faEllipsisVertical,
  faEye,
  faInfo,
  faFileSignature,
  faXmark,
  faCommentDots,
  faTrashCan,
  faUserSlash,
  faEnvelope,
  faPenToSquare,
  faCheck,
  faEyeLowVision,
  faCircleArrowDown,
  faAngleDown,
  faAngleLeft,
  faSortDown,
  faArrowUpRightFromSquare,
  faArrowUp,
  faVolumeXmark,
  faVolumeHigh,
  faCircleExclamation,
  faRightFromBracket,
  faShareFromSquare,
  faMugHot,
  faHollyBerry,
  faBars,
  faCircleHalfStroke,
  faDownload,
  faQrcode,
  faUpload,
  faCommentSlash,
  faSquareCaretDown,
  faHand,
  faWifi,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faMoon,
  faSun,
  faGear,
  faGears,
  faCamera,
  faPaperPlane,
  faCheckDouble,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faExclamation,
  faCircleXmark,
  faComments,
  faUser,
  faEllipsisVertical,
  faEye,
  faInfo,
  faFileSignature,
  faDownload,
  faXmark,
  faCommentDots,
  faTrashCan,
  faUserSlash,
  faEnvelope,
  faPenToSquare,
  faCheck,
  faEyeLowVision,
  faCircleArrowDown,
  faAngleDown,
  faAngleLeft,
  faSortDown,
  faArrowUpRightFromSquare,
  faArrowUp,
  faVolumeHigh,
  faCircleExclamation,
  faRightFromBracket,
  faShareFromSquare,
  faMugHot,
  faHollyBerry,
  faBars,
  faCircleHalfStroke,
  faQrcode,
  faUpload,
  faCommentSlash,
  faSquareCaretDown,
  faHand,
  faWifi,
  faVolumeXmark,
  faPlay,
  faPause
)
