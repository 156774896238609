import { createModal } from "./widget/popup";

export const box = {
  baseURL: "https://ftc-backend-zhehxkelwq-ew.a.run.app",
  applicationHeight: window.innerHeight,
  validImgTypes: "image/jpeg",
  browser: null,
  isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,

  openModal(component, data, style) {
    createModal(component, data, style);
  },
};
