<template>
  <div class="message mt-[6px] media relative">
    <div
      v-if="attachment.is_popup"
      @click="handleIframe"
      class="media-overlay bg-black opacity-0 absolute w-[98%] h-[97%]"
    ></div>

    <iframe
      class="w-full"
      :height="attachment.is_popup ? '210' : '320'"
      :src="attachment.link"
      frameborder="0"
      allowfullscreen
    ></iframe>
    <ShareLinks v-if="message.sharing" :sharing="message.sharing" />
  </div>
</template>
<script setup>
import { box } from "@/utils/box";
import IframeModal from "@/components/composables/modal/IframeModal.vue";
const props = defineProps({
  message: {
    type: Object,
    required: false,
  },
  attachment: {
    type: Object,
    required: false,
    default: {},
  },
});

const handleIframe = () => {
  if (!props.attachment.is_popup) return;
  box.openModal(
    IframeModal,
    { message: props.message, attachment: props.attachment },
    "w-full h-full flex items-center justify-center"
  );
};
</script>
