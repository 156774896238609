<template>
    <div class="audio-player">
      <audio ref="audio" :src="audioSrc" @timeupdate="updateProgress"></audio>
      <button @click="togglePlay">
        {{ isPlaying ? 'Pause' : 'Play' }}
      </button>
      <div class="progress-bar" @click="seek($event)">
        <div class="progress" :style="{ width: `${progress}%` }"></div>
      </div>
      <div>{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  
  export default {
    setup() {
      const audioSrc = ref('path-to-your-audio-file.mp3');
      const isPlaying = ref(false);
      const progress = ref(0);
      const currentTime = ref(0);
      const duration = ref(0);
      const audio = ref(null);
  
      onMounted(() => {
        const audioElement = audio.value;
        audioElement.onloadedmetadata = () => {
          duration.value = audioElement.duration;
        };
      });
  
      const togglePlay = () => {
        const audioElement = audio.value;
        if (isPlaying.value) {
          audioElement.pause();
        } else {
          audioElement.play();
        }
        isPlaying.value = !isPlaying.value;
      };
  
      const updateProgress = () => {
        const audioElement = audio.value;
        currentTime.value = audioElement.currentTime;
        progress.value = (audioElement.currentTime / audioElement.duration) * 100;
      };
  
      const seek = (event) => {
        const audioElement = audio.value;
        const progressBar = event.currentTarget;
        const clickPosition = event.offsetX / progressBar.offsetWidth;
        audioElement.currentTime = clickPosition * audioElement.duration;
      };
  
      const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      };
  
      return {
        audioSrc,
        isPlaying,
        progress,
        currentTime,
        duration,
        audio,
        togglePlay,
        updateProgress,
        seek,
        formatTime,
      };
    },
  };
  </script>
  
  <style scoped>
  .audio-player {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  button {
    margin-bottom: 10px;
  }
  
  .progress-bar {
    width: 100%;
    height: 5px;
    background-color: #ddd;
    cursor: pointer;
    position: relative;
  }
  
  .progress {
    height: 100%;
    background-color: #4caf50;
  }
  </style>
  