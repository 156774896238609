<template>
  <div class="flex items-center gap-[10px]">
    <div class="w-[34px] h-[34px]">
      <img src="/assets/images/logo/Avatar_2.png" />
    </div>
    <div class="text-xl bg-blue">
      <span class="font-bold text-white"
        >{{ $t("botName") }}
        <span class="text-sm font-semibold text-theme-primary">(V15)</span>
      </span>
    </div>
  </div>
</template>
