import axios from "@/lib/appAxios";

class services {
  // getSession
  async getSession({ cupId, payload }) {
    return axios.get(`/session/${cupId ? cupId : null}`, payload);
  }

  // sendMessage
  async sendMessage({ sessionId, payload }) {
    return axios.post(`/chat/${sessionId}`, payload);
  }

  // image upload
  async uploadImage({ sessionId, payload }) {
    return axios.post(`/upload/${sessionId}`, payload);
  }

  // getChatMessages
  async getChatMessages({ sessionId }) {
    return axios.get(`/chat/${sessionId}`);
  }

  // deleteUser
  async deleteUser() {
    return axios.delete("/user");
  }

  // logout
  async logout() {
    return axios.post("/logout");
  }

  // deleteChat
  async deleteChat({ sessionId }) {
    return axios.delete(`/chat/${sessionId}`);
  }
}

export default new services();
