<template>
  <!-- .message-content start -->

  <div
    @click="messageContentDivClick"
    @scroll="scrolling"
    :style="`height: ${windowHeight - 105 + 'px'}`"
    ref="messageContent"
    id="message-content"
    class="overflow-x-scroll h-full p-[15px] bg-theme-accent message-texture dark:bg-black bg-contain flex flex-col-reverse gap-[10px]"
  >
    <MessageBox
      @sendMessage="sendMessage"
      :allMessages="allMessages"
      :loaders="loaders"
    />
    <ScrollToBottom
      v-if="showScrollToBottom"
      @handleScrollToBottom="handleScrollToBottom"
      :scrollTop="scrollTop"
    />
  </div>
  <!-- .message-content finish -->
  <!-- .send-message start -->
  <div
    id="send-message"
    class="h-[60px] w-full px-[15px] bg-theme-accent dark:bg-black flex justify-between items-center gap-3"
  >
    <!-- CameraRear start -->
    <!-- <CameraRear
      :attachment="
        allMessages[0]?.attachments.find(
          (attachment) => attachment.type == attachmentTypes.TEXT
        )
      "
      :sendMessageWithImage="sendMessageWithImage"
    /> -->
    <CameraRear
      v-if="
        allMessages[0]?.userMessageType === userMessageTypes.CAMERA_REAR &&
        allMessages[0].messageWriter
      "
      :sendMessageWithImage="sendMessageWithImage"
      :attachment="
        allMessages[0]?.attachments.find(
          (attachment) => attachment.type == attachmentTypes.TEXT
        )
      "
    />
    <!-- CameraRear finish -->

    <!-- CameraFront start -->
    <!-- <CameraFront :sendMessageWithImage="sendMessageWithImage" /> -->
    <!-- CameraFront finish -->

    <SendMessage
      :loaders="loaders"
      @sendMessage="sendMessage"
      :allMessages="allMessages"
    />
  </div>
  <!-- .send-message finish -->
</template>

<script setup>
  // Components
  import MessageBox from "@/pages/components/MessageBox.vue"
  import SendMessage from "@/pages/components/SendMessage.vue"
  import ScrollToBottom from "@/components/composables/ScrollToBottom.vue"
  import CameraRear from "@/pages/components/messages/cameras/CameraRear.vue"
  import CameraFront from "@/pages/components/messages/cameras/CameraFront.vue"

  // Utils
  import {
    sendMessageTypes,
    messageTypes,
    userMessageTypes,
    attachmentTypes,
    fakeHistory,
  } from "@/utils/enums"
  import services from "@/services/index"
  import { useSound } from "@vueuse/sound"
  import { MessageModel, PaginationMessageModel } from "@/store/message/model"
  import { getRouteQueryParams, getDate } from "@/utils/settings"
  import { userSettings } from "@/store/userSettings.js"
  import { localSetItem, localGetItem } from "@/utils/local.js"

  // Vue
  import {
    computed,
    onMounted,
    provide,
    reactive,
    ref,
    watch,
    onUnmounted,
  } from "vue"

  // Public
  import send from "/public/assets/sounds/send.mp3"
  import _ from "lodash"

  import { useRoute, useRouter } from "vue-router"

  // Route
  const route = useRoute()
  const router = useRouter()

  // Sounds
  const sendSoundEffect = useSound(send)

  const messageContent = ref(null)

  class emptyMessage {
    constructor() {
      this.query = ""
      this.language = userSettings.language
    }
  }

  class userEmptyMessage {
    constructor() {
      this.messageType = messageTypes.USER
      this.message = ""
      this.userMessageType = userMessageTypes.TEXT
      this.timestamp = new Date()
      this.timestamp_t = getDate(this.timestamp)
      this.read = false
    }
  }

  const windowHeight = ref(window.innerHeight)

  const handleResize = () => {
    windowHeight.value = window.innerHeight
    document.getElementById("message-content").click() // Örnek olarak bir elementin click olayını tetikleyelim
    handleScrollToBottom()
  }

  // Scroll
  const showScrollToBottom = ref(false)
  const scrollTop = ref(0)

  const formData = reactive(new emptyMessage())

  const loaders = reactive({
    sendMessage: true,
    firstMessage: true,
  })

  const allMessages = ref([])

  // Scrolling
  const scrolling = (e) => {
    scrollTop.value = e.target.scrollTop
    if (scrollTop.value < -120) showScrollToBottom.value = true
    else showScrollToBottom.value = false
  }

  const handleScrollToBottom = () => {
    messageContent.value.scrollTop = 0
  }

  // First message
  const firstMessage = () => {
    let customQuery = route.query.dfKey
    if (customQuery) router.replace({ query: {} })

    loaders.sendMessage = true
    sendMessage({
      query: !customQuery ? userSettings.query : customQuery,
      type: sendMessageTypes.firstMessage,
      isFirstMessage: true,
    })
  }

  // Send message
  const sendMessage = ({ query, type, message, isFirstMessage = false }) => {
    if (type === sendMessageTypes.readyAnswers) {
      message.readyAnswersForReplySent = true
    }

    if (userSettings.messageSound && !isFirstMessage) sendSoundEffect.play()

    loaders.sendMessage = true

    // Kullanıcının mesajı
    if (sendMessageTypes.firstMessage != type) {
      const userMessage = new userEmptyMessage()
      userMessage.message = query
      userMessage.lailaMessageType = allMessages.value[0]?.userMessageType
      allMessages.value.unshift(userMessage)
    }

    // Chatbot gönderdiğimiz data
    const copyData = new emptyMessage()
    copyData.query = query
    copyData.user_message_type = userMessageTypes.TEXT

    sendMessageForDialogFlow({ copyData, isFirstMessage })
  }

  // Sendmessage for dialogflow
  const sendMessageForDialogFlow = ({
    copyData,
    isImage = false,
    isFirstMessage = false,
  }) => {
    if (allMessages.value[1]?.attachments[0]?.type === attachmentTypes.LOTTIE)
      messageReading({
        message: allMessages.value[0],
        status: true,
        delay: 0,
      })

    handleScrollToBottom()
    services
      .sendMessage({
        sessionId: userSettings.session_id,
        payload: copyData,
        cupId: getRouteQueryParams(window.location.pathname),
      })
      .then((res) => {
        if (allMessages.value[0]?.messageType == messageTypes.USER)
          messageReading({ message: allMessages.value[0], status: true })
        setTimeout(
          () => {
            allMessages.value.unshift(new MessageModel(res.data))
            loaders.sendMessage = false
            loaders.firstMessage = false
          },
          isFirstMessage ? 0 : 2000
        ) // 2 saniye sonra tetiklenecek
      })
      .catch((err) => {
        if (err.response?.status == "401") {
          firstStart()
        }
        if (allMessages.value[0]?.messageType == messageTypes.USER)
          messageReading({ message: allMessages.value[0], status: false })
        loaders.sendMessage = false
        loaders.firstMessage = false
      })
  }

  //
  const sendMessageWithImage = ({ image, query }) => {
    loaders.sendMessage = true

    // Kullanıcının mesajı
    const userMessage = new userEmptyMessage()
    userMessage.userMessageType = userMessageTypes.IMAGE
    userMessage.message = image.imagePreview
    userMessage.localImage = image.localImage
    allMessages.value.unshift(userMessage)

    // Chatbot gönderdiğimiz data
    const copyData = new emptyMessage()
    copyData.query = query
    copyData.user_message_type = userMessageTypes.IMAGE

    sendMessageForDialogFlow({ copyData, isImage: true })
  }

  // getAllChatMessages

  const getAllChatMessages = async () => {
    // fakeHistory.map((item, index) => {
    //   allMessages.value.push(new PaginationMessageModel(item, fakeHistory[0]))
    //   loaders.sendMessage = false
    //   loaders.firstMessage = false
    // })
    await services
      .getChatMessages({ sessionId: userSettings.session_id })
      .then((res) => {
        res.data.history.map((item, index) => {
          allMessages.value.push(
            new PaginationMessageModel(item, res.data.history[0])
          )
          loaders.sendMessage = false
          loaders.firstMessage = false
        })
      })
      .catch((err) => {
        loaders.sendMessage = false
        console.log("err", err)
      })
  }

  const firstStart = async () => {
    await userLocalSettings()
    await getAllChatMessages()
    if (allMessages.value.length > 0) return
    firstMessage()
  }

  // Kullanıcının ayarlarını yaptığımız fonksyion

  const userLocalSettings = async () => {
    await userSettings.updateAsyncData()
    if (!localGetItem("userSettings")) {
      return localSetItem("userSettings", JSON.stringify(userSettings))
    } else {
      const newObj = JSON.parse(localGetItem("userSettings"))

      userSettings
      newObj.query = userSettings.query
      newObj.cup_id = userSettings.cup_id
      newObj.session_id = userSettings.session_id
      newObj.token = userSettings.token
      delete newObj.isGuest
      if (!newObj.cuppScanCount) newObj.cuppScanCount = 0
      Object.assign(userSettings, newObj)

      if (newObj.userTheme === "dark")
        document.documentElement.classList.add("dark")
      localSetItem("userSettings", JSON.stringify(newObj))
    }
  }

  // Okundu bilgisi

  const messageReading = ({ message, status, delay = 1500 }) => {
    setTimeout(() => {
      if (message.read) return
      message.read = status
    }, delay) // 2 saniye sonra tetiklenecek
  }

  onMounted(async () => {
    window.addEventListener("resize", handleResize)
    handleScrollToBottom()
    await firstStart()
  })

  onUnmounted(() => {
    window.removeEventListener("resize", handleResize)
  })

  //
</script>
