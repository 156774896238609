if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("Service worker registered:", registration);
        registration.addEventListener("updatefound", () => {
          const installingWorker = registration.installing;
          installingWorker.addEventListener("statechange", () => {
            if (installingWorker.state === "installed") {
              if (navigator.serviceWorker.controller) {
                // Yeni bir sürüm yüklendiğinde kullanıcıya bildirim gönder
                console.log("New content is available; please refresh.");
              }
            }
          });
        });
      })
      .catch((error) => {
        console.error("Error during service worker registration:", error);
      });
  });
}
